import React from "react";
import error from "../img/err_500.jpg";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <div className="error-box-container" data-testid="ErrorBox">
        <div className="error-img-container">
          <img src={error} alt="error" className="error-img" />
        </div>
        <div className="empty-cart-text">You are not logged in</div>
        <div className="checkout_btn_container">
          <button className="checkout_btn">
            <Link to="/login" className="manage-orders-link">
              Login
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default Error;
