import React from "react";
import { Link } from "react-router-dom";
import paymentSuccess from "../img/payment-success.jpg";

const PaymentSuccess = () => {
  return (
    <>
      <div className="payment-success-container" data-testid="PaymentSuccess">
        <img
          src={paymentSuccess}
          className="payment-success-img"
          alt="success"
        />
        <div style={{ margin: "30px 0px" }}>
          <button className="checkout_btn">
            <Link className="manage-orders-link" to="/account/orders">
              My Orders
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
