import React from "react";
import Gallery from "./Gallery";
import pay from "../img/pay.png";
import order from "../img/order.png";
import delievery from "../img/delievery.png";
import checklist from "../img/checklist.png";
const Home = () => {
  return (
    <>
      <section className="delievery_section">
        <div className="delievery_text_box">
          <div className="text-header">
            Get Your Order 24/7 Right At Your Doorsteps
          </div>
          <div className="text-para">
            For over a decade now, we’ve been empowering our users in
            discovering new tastes and experiences across countries. By putting
            together meticulous information for our users, we enable them to
            make an informed choice.
          </div>
        </div>
        <div className="delievery_photo_container">
          <img src={delievery} alt="delievery" className="big-image" />
        </div>
      </section>
      <div className="heading">Our Services</div>
      <section className="service_section">
        <div className="service_box">
          <div className="service_img_box">
            <img src={checklist} alt="service" className="service-image" />
          </div>
          <div className="service-text-box">
            <div className="service-header">Your Order</div>
            <div className="service-para">
              Thank you for being valued customer. We are so grateful to serving
              for the honored be clients pleasure of serving hope we meets.
            </div>
          </div>
        </div>
        <div className="service_box">
          <div className="service_img_box">
            <img src={pay} alt="service" className="service-image" />
          </div>
          <div className="service-text-box">
            <div className="service-header">Online Payment</div>
            <div className="service-para">
              Thank you for being valued customer. We are so grateful to serving
              for the honored be clients pleasure of serving hope we meets.
            </div>
          </div>
        </div>
        <div className="service_box">
          <div className="service_img_box">
            <img src={order} alt="service" className="service-image" />
          </div>
          <div className="service-text-box">
            <div className="service-header">Recieve Order</div>
            <div className="service-para">
              Thank you for being valued customer. We are so grateful to serving
              for the honored be clients pleasure of serving hope we meets.
            </div>
          </div>
        </div>
      </section>
      <Gallery />
    </>
  );
};

export default Home;
