import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import sticky from "../img/sticky.svg";
const Header = () => {
  return (
    <>
      <Navbar
        className="header"
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container>
          <Navbar.Brand href="/" style={{ color: "black" }}>
            <img src={sticky} alt="logo" style={{ width: "200px" }} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ color: "black" }}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ background: "#f7f9fb" }}
          >
            <Nav className="ml-auto">
              <Link to="/" className="navlink">
                Home
              </Link>
              <Link to="/login" className="navlink">
                Login
              </Link>
              <Link to="/signup" className="navlink">
                Signup
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
