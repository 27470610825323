import React, { useState, useEffect } from "react";
import axios from "axios";
import RestaurantCard from "./RestaurantCard";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import design from "../../img/design.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Dashboard = () => {
  let history = useNavigate();
  const [items, setItems] = useState([]);
  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });

  const { severity, message } = alertData;
  const [openModal, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    type: "",
    opensAt: "",
    rating: "",
    description: "",
  });
  const handleCloseModal = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 500,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      history("/admin");
    }, 3000);
  };
  const changeDangerAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      history("/admin");
    }, 3000);
  };

  useEffect(() => {
    let token = localStorage.getItem("jwt");
    if (!token) {
      history("/error");
    } else {
      axios
        .get(
          "https://foodota-backend.onrender.com/api/v1/restaurants/getRestaurants"
        )
        .then(function (response) {
          console.log(response.data.restaurants);
          setItems(response.data.restaurants);
        })
        .catch(function (error) {});
    }
  }, [history]);
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://foodota-backend.onrender.com/api/v1/restaurants/addRestaurant",
        formData
      )
      .then((res) => {
        console.log(res.data);
        setAlertData({
          message: "Restaurant Added Succesfully!",
          severity: "success",
        });
        handleClose();
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
        setAlertData({
          message: "Error Adding Restaurant!",
          severity: "error",
        });
        changeDangerAlert();
      });
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Modal
        style={{ border: "none" }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="form">
            <div className="input-container">
              <input
                type="text"
                placeholder="Name..."
                name="name"
                value={formData.name}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Location..."
                name="location"
                value={formData.location}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Type..."
                name="type"
                value={formData.type}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Opens At..."
                name="opensAt"
                value={formData.opensAt}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Rating..."
                name="rating"
                value={formData.rating}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Description..."
                name="description"
                value={formData.description}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="submit"
                value="Submit"
                className="submit_btn"
                onClick={onSubmit}
              />
            </div>
          </form>
        </Box>
      </Modal>
      <div className="restaurant-card-container">
        {items.map((item, index) => {
          return <RestaurantCard key={index} data={item} />;
        })}
        <Card sx={{ maxWidth: 345 }} style={{ margin: "40px auto" }}>
          <CardMedia
            component="img"
            height="140"
            image={design}
            alt="green iguana"
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ fontFamily: "Josefin Sans" }}
            >
              <div className="add-container">
                <div style={{ textAlign: "center", margin: "auto auto" }}>
                  <AddIcon
                    className="add-icon"
                    onClick={handleOpen}
                    style={{
                      fontSize: "50px",
                    }}
                  />
                </div>
              </div>
            </Typography>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </div>
    </>
  );
};

export default Dashboard;
