import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const RestaurantCard = (props) => {
  let history = useNavigate();
  const { _id, name, description, opensAt, rating, location, imgPath } =
    props.data;

  const redirectAddress = `./restaurants/manage-orders/${_id}`;

  const redirectPage = () => {
    history(`./restaurant/${_id}`);
  };
  return (
    <>
      <Card sx={{ maxWidth: 345 }} style={{ margin: "40px auto" }}>
        <CardMedia
          component="img"
          height="140"
          image={imgPath}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ fontFamily: "Josefin Sans" }}
          >
            {name} , {location}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            {rating} <StarIcon style={{ color: "yellow", fontSize: "20px" }} />
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            {description}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ marginTop: "20px", fontFamily: "Josefin Sans" }}
          >
            {opensAt}
          </Typography>
        </CardContent>
        <CardActions>
          <div className="delete-icon">
            <DeleteOutlineIcon />
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button className="view-menu-btn" onClick={redirectPage}>
              Manage Products
            </button>
            <button className="view-menu-btn">
              <Link to={redirectAddress} className="manage-orders-link">
                Manage Orders
              </Link>
            </button>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default RestaurantCard;
