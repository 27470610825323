import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import design from "./../img/design.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import delievery from "./../img/delievery_status.jpg";

const OrderCard = (props) => {
  const [openModal, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 450,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  const { Address, Amount, items, placedAt, restaurant, status } = props.data;
  return (
    <>
      <Modal
        style={{ border: "none" }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order-list-container">
            {items.map((product, index) => {
              return (
                <>
                  <div className="order-item-container">
                    <div
                      style={{
                        textAlign: "center",
                        padding: "10px 10px",
                        width: "30%",
                      }}
                    >
                      <img
                        src={product.price.product.images[0]}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <div style={{ padding: "2px 2px" }}>
                      <div>{product.description}</div>
                      <div>Quantity: {product.quantity}</div>
                      <div>&#x20B9; {product.amount_subtotal / 100}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div
            style={{ fontSize: "25px", textAlign: "center", marginTop: "30px" }}
          >
            Total : &#x20B9; {Amount}
          </div>
        </Box>
      </Modal>
      <Card sx={{ maxwidth: 345 }} style={{ margin: "40px auto" }}>
        <CardMedia
          component="img"
          height="140"
          image={design}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ fontFamily: "Josefin Sans" }}
          ></Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            Address: {Address}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            {restaurant.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ marginTop: "20px", fontFamily: "Josefin Sans" }}
          >
            {new Date(placedAt).toLocaleString()}
          </Typography>
        </CardContent>
        <CardActions>
          <div style={{ width: "100%", textAlign: "left" }}>
            <img
              src={delievery}
              alt="delievery"
              style={{ width: "40px", height: "40px" }}
            />
            <span style={{ padding: "5px 10px" }}>{status}</span>
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button className="view-menu-btn" onClick={handleOpen}>
              View Order
            </button>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default OrderCard;
