import React from "react";
import Menu from "@mui/material/Menu";
import { connect } from "react-redux";
import { LOG_OUT_USER } from "../redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import dummy from "../img/dummy.png";
import sticky from "../img/sticky.svg";

const AccountNavbar = (props) => {
  let history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    props.logout();
    history("/");
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          {props.userData.user.role === "admin" ? (
            <Link to="/admin" className="view-orders">
              Home
            </Link>
          ) : (
            <Link to="/account" className="view-orders">
              Home
            </Link>
          )}
        </MenuItem>
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        {props.userData.user.role === "user" ? (
          <MenuItem>
            <Link to="/account/orders" className="view-orders">
              View Orders
            </Link>
          </MenuItem>
        ) : (
          ""
        )}

        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <div className="header">
        <div className="logo-text">
          <img
            src={sticky}
            alt="logo"
            style={{ width: "200px", height: "100px" }}
          />
        </div>
        <div className="user-account-logo">
          <img
            src={dummy}
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          />

          <KeyboardArrowDownIcon
            onClick={handleClick}
            style={{
              color: "black",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(LOG_OUT_USER()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountNavbar);
