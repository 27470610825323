import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import loginLogo from "../img/loginLogo.jpg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Signup = () => {
  let history = useNavigate();
  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });

  const { severity, message } = alertData;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    passwordConfirm: "",
  });

  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      history("/login");
    }, 6000);
  };
  const changeDangerAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      history("/signup");
    }, 1000);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://foodota-backend.onrender.com/api/v1/users/signup",
        formData
      )
      .then((res) => {
        console.log(res.data);
        setAlertData({
          message: "Account Created Successfully!",
          severity: "success",
        });
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
        setAlertData({
          message: "User already exists",
          severity: "error",
        });
        changeDangerAlert();
      });
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="signup_container" data-testid="FormContainer">
        <form className="form">
          <div className="image-container">
            <img
              src={loginLogo}
              alt="logo"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Name..."
              name="name"
              value={formData.name}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Email..."
              name="email"
              value={formData.email}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Phone..."
              name="phone"
              value={formData.phone}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password..."
              name="password"
              value={formData.password}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password Confirm..."
              name="passwordConfirm"
              value={formData.passwordConfirm}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="submit"
              value="Submit"
              className="submit_btn"
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Signup;
