import React from "react";
import emptyCart from "../img/empty-cart.png";
const EmptyCart = () => {
  return (
    <>
      <div className="empty-cart-container" data-testid="EmptyCart">
        <img className="empty-cart-img" src={emptyCart} alt="empty" />
        <div className="empty-cart-text">Please add some products!!! 😋</div>
      </div>
    </>
  );
};

export default EmptyCart;
