import React from "react";
import { Provider } from "react-redux";
import Home from "./components/Home";
import Header from "./components/Header";
import Dashboard from "./components/Admin/Dashboard";
import Footer from "./components/Footer";
import Product from "./components/Admin/Product";
import OrderSummary from "./components/OrderSummary";
import AccountNavbar from "./components/AccountNavbar";
import ViewOrders from "./components/ViewOrders";
import Account from "./components/Account";
import Login from "./components/Login";
import Products from "./components/Products";
import Error from "./components/Error";
import { Route, Routes } from "react-router-dom";
import Store from "./redux/Store";
import Signup from "./components/Signup";
import PaymentSuccess from "./components/PaymentSuccess";
import Order from "./components/Admin/Order";
function App() {
  return (
    <>
      <Provider store={Store}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/signup"
            element={
              <>
                <Header />
                <Signup />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/error"
            element={
              <>
                <Header />
                <Error />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/admin"
            element={
              <>
                <AccountNavbar />
                <Dashboard />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="account/order-summary"
            element={
              <>
                <AccountNavbar />
                <OrderSummary />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="account/orders"
            element={
              <>
                <AccountNavbar />
                <ViewOrders />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/account"
            element={
              <>
                <AccountNavbar />
                <Account />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/restaurant/:restaurant"
            element={
              <>
                <AccountNavbar />
                <Products />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="admin/restaurant/:restaurant"
            element={
              <>
                <AccountNavbar />
                <Product />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="admin/restaurants/manage-orders/:restaurant"
            element={
              <>
                <AccountNavbar />
                <Order />
                <Footer />
              </>
            }
          ></Route>
          <Route
            exact
            path="/payment-success"
            element={
              <>
                <AccountNavbar />
                <PaymentSuccess />
                <Footer />
              </>
            }
          ></Route>
        </Routes>
      </Provider>
    </>
  );
}

export default App;
