import { LOGIN_USER } from "./../types";
import { LOGIN_FAILED, LOG_OUT, LOGIN_SUCCESS } from "./../types";
const initialState = {
  token: "",
  user: {},
  err: "",
  isLoading: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        token: "",
        user: {},
        err: "",
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        token: action.payload.token,
        user: action.payload.data.user,
        err: "",
        isLoading: false,
      };
    case LOGIN_FAILED: {
      return {
        token: "",
        user: {},
        err: action.payload,
        isLoading: false,
      };
    }
    case LOG_OUT: {
      return {
        token: "",
        user: {},
        err: "",
        isLoading: undefined,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
