import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loginUser } from "../redux";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import loginLogo from "../img/loginLogo.jpg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = (props) => {
  let history = useNavigate();

  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });

  const { severity, message } = alertData;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };

  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    if (props.userData.err === "Failed") {
      setAlertData({
        severity: "error",
        message: "Invalid username or password!",
      });
      setState({ ...state, open: true });
      setTimeout(() => {
        setState({ ...state, open: false });
      }, 3000);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      setAlertData({
        severity: "success",
        message: "Login Successful!",
      });
      setState({ ...state, open: true });
      setTimeout(() => {
        setState({ ...state, open: false });
        if (props.userData.user.role === "admin") {
          history("/admin");
        } else {
          history("/account");
        }
      }, 2000);
    }
  };
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.login(formData);
  };

  useEffect(() => {
    if (props.userData.isLoading === false) {
      changeAlert();
    }
    //eslint-disable-next-line
  }, [props.userData.isLoading]);
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="form_container" data-testid="FormContainer">
        <form className="form">
          <div className="image-container">
            <img
              src={loginLogo}
              alt="logo"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Email..."
              name="email"
              value={formData.email}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password..."
              name="password"
              value={formData.password}
              onChange={onChange}
            />
          </div>
          <div className="input-container">
            <input
              type="submit"
              value="Submit"
              className="submit_btn"
              data-testid="LOGIN"
              onClick={onSubmit}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(loginUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
