import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const Gallery = () => {
  const itemData = [
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/oepnw501ertjfxiuhu8f.jpg",
      title: "Breakfast",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/hygikfz3nmy8zc3zrjvk.jpg",
      title: "Burger",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/lriepuwtimzp4kyai4vd.jpg",
      title: "Camera",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/kneroc6abcuaqvyddj4e.jpg",
      title: "Coffee",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/msevujw8ivstalxhgctx.jpg",
      title: "Hats",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637392599/Foodota/Gallery/z0cevgkui9d5eaatqxlf.jpg",
      title: "Honey",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637393622/Foodota/Gallery/w5gkf59nocg4ooifjxz6.jpg",
      title: "Basketball",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637393622/Foodota/Gallery/ljwoltuqvpf0qrado3wg.jpg",
      title: "Fern",
    },
    {
      img: "https://res.cloudinary.com/prateek1406/image/upload/v1637393624/Foodota/Gallery/z5qamw2bdxrhe21psgbj.jpg",
      title: "Mushrooms",
    },
  ];

  return (
    <>
      <div className="heading">Our Gallery</div>
      <ImageList
        sx={{ width: "auto", height: 600 }}
        cols={3}
        rowHeight={200}
        style={{ margin: "0px auto", overflowY: "hidden" }}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default Gallery;
