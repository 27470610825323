import React from "react";
import noOrder from "../img/no-order.png";
const NoOrder = () => {
  return (
    <>
      <div className="no-order-container">
        <div className="no-order-img-container">
          <img src={noOrder} alt="no-order" className="no-order-img" />
        </div>
      </div>
    </>
  );
};

export default NoOrder;
