import React, { useState } from "react";
import axios from "axios";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Card = (props) => {
  const { imgPath, slug, title, description, price } = props.data;
  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });

  const { severity, message } = alertData;
  const { boxRef } = props;
  let { restaurant } = useParams();
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const [quantity, setQuantity] = useState(1);
  const reduceQuantity = () => {
    if (quantity === 0) {
      setQuantity(0);
    } else {
      setQuantity(quantity - 1);
    }
  };
  const { vertical, horizontal, open } = state;

  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {}, 6000);
  };

  const addToCart = () => {
    let token = localStorage.getItem("jwt");
    axios
      .post(
        "https://foodota-backend.onrender.com/api/v1/cart/addToCart",
        {
          slug: slug,
          quantity: 1,
          restaurant: restaurant,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        boxRef.current.classList.add("effect");
        setTimeout(() => {
          boxRef.current.classList.remove("effect");
        }, 2000);
        setAlertData({
          severity: "success",
          message: "Product Added to Cart!",
        });
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
        setAlertData({
          severity: "error",
          message:
            "Error! Can't Add Product from another restaurant.Please remove cart items",
        });
        changeAlert();
      });
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="box" data-testid="ProductBox">
        <div className="main">
          <div className="item-info">
            <div className="photo_container">
              <img
                src={imgPath}
                alt="pic"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%;",
                }}
              />
            </div>
            <div className="item-details">
              <div className="item-title">{title}</div>
              <div className="item-desc">{description}</div>
              <div className="item-price"> &#x20B9; {price}</div>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <div className="quantity_box">
            <div style={{ width: "30px" }}>
              <button className="quantity_btn" onClick={reduceQuantity}>
                <RemoveIcon style={{ fontSize: "15px", margin: "5px auto" }} />
              </button>
            </div>
            <div
              style={{ width: "40px", fontSize: "20px", textAlign: "center" }}
            >
              {quantity}
            </div>
            <div style={{ width: "30px" }}>
              <button
                className="quantity_btn"
                onClick={() => {
                  setQuantity(quantity + 1);
                }}
              >
                <AddIcon style={{ fontSize: "15px", margin: "5px auto" }} />
              </button>
            </div>
          </div>
          <button className="addtocart" onClick={addToCart}>
            Add to cart
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
