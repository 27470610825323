import axios from "axios";
import { LOAD_CART } from "./types";
import { DELETE_FROM_CART } from "./types";
export const UPLOAD_CART = (data) => {
  return {
    type: LOAD_CART,
    payload: data,
  };
};
export const DeleteFromCart = (id) => {
  return {
    type: DELETE_FROM_CART,
    payload: id,
  };
};
export const loadCart = () => {
  let token = localStorage.getItem("jwt");
  return (dispatch) => {
    axios
      .get("https://foodota-backend.onrender.com/api/v1/cart/getCart", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data.products;
        dispatch(UPLOAD_CART(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
