import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadCart } from "../redux";
import CartItem from "./CartItem";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const stripePromise = loadStripe(
  "pk_test_51JwiDqSBscTDobfgYgQgG4KImd3zp6RzInXYz8qchs4c9jvGX4Dzr52vejo0yaVVD6maSHlVtrZpIUPVbSv48hCr00LopRwfVe"
);

const OrderSummary = (props) => {
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;

  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {}, 6000);
  };
  useEffect(() => {
    props.getCart();
  }, [props]);

  const [id, setID] = useState("");
  const [address, setAddress] = useState("");
  const redirect_Checkout = async () => {
    const stripe = await stripePromise;
    let token = localStorage.getItem("jwt");

    const launchPaymentGateway = async () => {
      await stripe.redirectToCheckout({
        sessionId: id,
      });
    };

    if (address === "") {
      changeAlert();
    } else {
      const billing_address = {
        address: address,
      };
      axios
        .post(
          "https://foodota-backend.onrender.com/api/v1/orders/check-out-session",
          billing_address,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(function (response) {
          console.log(response.data.checkout_session);
          setID(response.data.checkout_session.id);
          launchPaymentGateway();
        })
        .catch(function (error) {
          console.log(error.message);
        });
    }
    // console.log(token);
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Please enter your address
        </Alert>
      </Snackbar>
      <div className="container-fluid" style={{ padding: "0", margin: "0" }}>
        <div className="row" style={{ padding: "0", margin: "0" }}>
          {props.cartData.cart.map((item, index) => {
            return (
              <>
                <div className="col-lg-4" style={{ marginTop: "30px" }}>
                  <CartItem key={index} data={item} />
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className="total-price">Total : &#x20B9; {props.cartData.total}</div>
      <div className="input-container">
        <textarea
          className="address_textarea"
          rows="2"
          cols="3"
          placeholder="Enter your address"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="checkout_btn_container">
        <button className="checkout_btn" onClick={redirect_Checkout}>
          Pay Now
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCart: () => dispatch(loadCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
