import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { DeleteFromCart } from "../redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CartItem = (props) => {
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {}, 6000);
  };

  const deleteItem = () => {
    let token = localStorage.getItem("jwt");
    axios
      .delete(
        `https://foodota-backend.onrender.com/api/v1/cart/deleteFromCart/${_id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        props.deletefromcart();
        console.log(props.cartData);
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { _id, subtotal, item, quantity } = props.data;
  console.log(props.data);
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Product Removed From Cart!
        </Alert>
      </Snackbar>
      <div className="cart-item-box" data-testid="CartItemBox">
        <div className="cart-product-imgbox">
          <img
            src={item.imgPath}
            alt="pic"
            style={{ width: "60px", height: "50px" }}
          />
        </div>
        <div className="cart-product-info">
          <div className="cart-product-title">{item.title}</div>
          <div className="cart-product-quantity">Quantity : {quantity}</div>
          <div className="cart-product-subtotal">
            Subtotal : &#x20B9; {subtotal}
          </div>
        </div>
        <div className="delete_button_container">
          <CancelIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={deleteItem}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deletefromcart: (_id) => dispatch(DeleteFromCart(_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
