import axios from "axios";
import { LOGIN_USER } from "./types";
import { LOGIN_FAILED, LOG_OUT, LOGIN_SUCCESS } from "./types";

export const LOGIN_ATTEMPT = () => {
  return {
    type: LOGIN_USER,
  };
};
export const LOAD_USER = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const LOG_OUT_USER = () => {
  return {
    type: LOG_OUT,
  };
};
export const LOGIN_FAILURE = (msg) => {
  return {
    type: LOGIN_FAILED,
    payload: msg,
  };
};
export const loginUser = ({ email, password }) => {
  return (dispatch) => {
    dispatch(LOGIN_ATTEMPT);
    axios
      .post("https://foodota-backend.onrender.com/api/v1/users/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data;
        localStorage.setItem("jwt", data.token);
        dispatch(LOAD_USER(data));
      })
      .catch((err) => {
        dispatch(LOGIN_FAILURE("Failed"));
      });
  };
};
