import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProductCard = (props) => {
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      //   history("/admin");
    }, 3000);
  };
  const deleteProduct = () => {
    let token = localStorage.getItem("jwt");
    axios
      .delete(
        `https://foodota-backend.onrender.com/api/v1/products/deleteProduct/${slug}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { slug, imgPath, title, description, price } = props.data;
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Product Deleted Successfully
        </Alert>
      </Snackbar>
      <div className="box">
        <div className="main">
          <div className="item-info">
            <div className="photo_container">
              <img
                src={imgPath}
                alt="pic"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%;",
                }}
              />
            </div>
            <div className="item-details">
              <div className="item-title">{title}</div>
              <div className="item-desc">{description}</div>
              <div className="item-price"> &#x20B9; {price}</div>
            </div>
          </div>
        </div>
        <div className="delete-icon" style={{ float: "right" }}>
          <DeleteOutlineIcon onClick={deleteProduct} />
        </div>
      </div>
    </>
  );
};

export default ProductCard;
