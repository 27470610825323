import { DELETE_FROM_CART, LOAD_CART } from "./../types";
const initialState = {
  cart: [],
  total: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CART:
      let amount = 0;
      for (let item of action.payload) {
        amount += item.subtotal;
      }
      return {
        ...state,
        total: amount,
        cart: action.payload,
      };
    case DELETE_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter((item) => item._id !== action.payload),
      };
    }
    default:
      return state;
  }
};

export default cartReducer;
