import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Card from "./Card";
import Modal from "@mui/material/Modal";
import { connect } from "react-redux";
import CartItem from "./CartItem";
import { loadCart } from "../redux";
import Box from "@mui/material/Box";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import EmptyCart from "./EmptyCart";

const Products = (props) => {
  const [openModal, setOpen] = useState(false);
  const cartRef = useRef();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  // console.log(props.cartData.cart);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 450,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };

  let { restaurant } = useParams();
  const [items, setItems] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://foodota-backend.onrender.com/api/v1/products/getProducts/${restaurant}`
      )
      .then(function (response) {
        console.log(response.data);
        setItems(response.data.products);
      })
      .catch(function (error) {});

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    props.getCart();

    //eslint-disable-next-line
  }, [props]);
  return (
    <>
      <Modal
        style={{ border: "none" }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {props.cartData.cart.length === 0 ? (
            <EmptyCart />
          ) : (
            <>
              {" "}
              {props.cartData.cart.map((item, index) => {
                return (
                  <>
                    <CartItem key={index} data={item} />
                  </>
                );
              })}
              <div className="total-price">
                Total : &#x20B9; {props.cartData.total}
              </div>
              <div className="checkout_btn_container">
                <button className="checkout_btn">
                  <Link
                    to="../account/order-summary"
                    className="manage-orders-link"
                  >
                    Check out
                  </Link>
                </button>
              </div>
              `
            </>
          )}
        </Box>
      </Modal>
      <div className="product-container" data-testid="ProductContainer">
        {items.map((item, index) => {
          return <Card key={index} data={item} boxRef={cartRef} />;
        })}
      </div>
      <div className="cart_box" ref={cartRef}>
        <div style={{ textAlign: "center", margin: "10px auto" }}>
          <ShoppingCartIcon onClick={handleOpen} style={{ color: "white" }} />
        </div>
        <div className="cart_quantity">{props.cartData.cart.length}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCart: () => dispatch(loadCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
