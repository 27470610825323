import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "./ProductCard";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Product = () => {
  let { restaurant } = useParams();
  const [items, setItems] = useState([]);
  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
    opensAt: "",
    price: 0,
    imgPath: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `https://foodota-backend.onrender.com/api/v1/products/addProduct/${restaurant}`,
        formData
      )
      .then((res) => {
        console.log(res.data);
        setAlertData({
          message: "Product Added Succesfully!",
          severity: "success",
        });
        handleClose();
        changeAlert();
      })
      .catch((err) => {
        console.log(err);
        setAlertData({
          message: "Error Adding Product!",
          severity: "error",
        });
        changeDangerAlert();
      });
  };

  const { severity, message } = alertData;
  const [openModal, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 500,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      //   history("/admin");
    }, 3000);
  };
  const changeDangerAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      //   history("/admin");
    }, 3000);
  };

  useEffect(() => {
    axios
      .get(
        `https://foodota-backend.onrender.com/api/v1/products/getProducts/${restaurant}`
      )
      .then(function (response) {
        console.log(response.data);
        setItems(response.data.products);
      })
      .catch(function (error) {});

    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Modal
        style={{ border: "none" }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="form">
            <div className="input-container">
              <input
                type="text"
                placeholder="Title..."
                name="title"
                value={formData.title}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Category..."
                name="category"
                value={formData.category}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Description..."
                name="description"
                value={formData.description}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Price..."
                name="price"
                value={formData.price}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Img URL..."
                name="imgPath"
                value={formData.imgPath}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              <input
                type="submit"
                value="Submit"
                className="submit_btn"
                onClick={onSubmit}
              />
            </div>
          </form>
        </Box>
      </Modal>
      <div className="product-container">
        {items.map((item, index) => {
          return <ProductCard key={index} data={item} />;
        })}
        <div className="box">
          <div className="main">
            <div className="add-container">
              <div style={{ textAlign: "center", margin: "auto auto" }}>
                <AddIcon
                  className="add-icon"
                  onClick={handleOpen}
                  style={{
                    fontSize: "50px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
