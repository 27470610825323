import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import sticky from "../img/sticky.svg";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <div className="footer_logo_box">
          <div>
            <img
              src={sticky}
              alt="logo"
              style={{ width: "200px", height: "100px" }}
            />
          </div>
          <div className="footer-text">
            One is to focus on the customer and improve our delievery, fooding
            service more better and to serve our customers with a better comfort
          </div>
          <div className="phone-icon">
            <PhoneIcon /> +92300-400-2333
          </div>
          <div className="phone-icon">
            <EmailIcon />
            restaurant@gmail.com
          </div>
        </div>
        <div className="social_media_box">
          <FacebookOutlinedIcon
            className="social-media-icon"
            style={{ fontSize: "50px" }}
          />
          <TwitterIcon
            className="social-media-icon"
            style={{ fontSize: "50px" }}
          />
          <InstagramIcon
            className="social-media-icon"
            style={{ fontSize: "50px" }}
          />
        </div>
      </section>
    </>
  );
};

export default Footer;
