import React, { useState, useEffect } from "react";
import axios from "axios";
import OrderCard from "./OrderCard";
import NoOrder from "./NoOrder";
const ViewOrders = () => {
  const [items, setItems] = useState([]);
  let token = localStorage.getItem("jwt");
  useEffect(() => {
    axios
      .get(
        `https://foodota-backend.onrender.com/api/v1/orders/getOrdersByUser`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        setItems(response.data.orders);
      })
      .catch(function (error) {});

    //eslint-disable-next-line
  }, []);
  return (
    <>
      {items.length === 0 ? (
        <NoOrder />
      ) : (
        <div className="restaurant-card-container">
          {items.map((item, index) => {
            return <OrderCard key={index} data={item} />;
          })}
        </div>
      )}
    </>
  );
};

export default ViewOrders;
