import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import axios from "axios";
import delievery from "../../img/delievery_status.jpg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import design from "../../img/design.png";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrderCard = (props) => {
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "right",
    open: false,
  });
  const [alertData, setAlertData] = useState({
    severity: "",
    message: "",
  });
  const { severity, message } = alertData;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };

  const cancelOrder = () => {
    const data = {
      status: "Cancelled",
    };
    let token = localStorage.getItem("jwt");
    axios
      .patch(
        `https://foodota-backend.onrender.com/api/v1/orders/updateOrderByAdmin/${_id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        setAlertData({
          message: "Order Cancelled Successfully",
          severity: "success",
        });
        changeAlert();
      })
      .catch(function (error) {
        console.log(error.message);
        setAlertData({
          message: "Problem cancelling order",
          severity: "error",
        });
      });
  };

  const changeStatus = () => {
    const data = {
      status: "Delievered",
    };
    let token = localStorage.getItem("jwt");
    axios
      .patch(
        `https://foodota-backend.onrender.com/api/v1/orders/updateOrderByAdmin/${_id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        setAlertData({
          message: "Order Updated Successfully",
          severity: "success",
        });
        changeAlert();
      })
      .catch(function (error) {
        console.log(error.message);
        setAlertData({
          message: "Error Updating Order",
          severity: "error",
        });
      });
  };
  const { vertical, horizontal, open } = state;
  const changeAlert = () => {
    setState({ ...state, open: true });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
    setTimeout(() => {
      //   history("/admin");
    }, 3000);
  };
  const [openModal, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: "30px",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 450,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };
  const { _id, Address, Amount, items, placedAt, restaurant, status } =
    props.data;
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Modal
        style={{ border: "none" }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order-list-container">
            {items.map((product, index) => {
              return (
                <>
                  <div key={index} className="order-item-container">
                    <div
                      style={{
                        textAlign: "center",
                        padding: "10px 10px",
                        width: "30%",
                      }}
                    >
                      <img
                        src={product.price.product.images[0]}
                        alt="pic"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                    <div style={{ padding: "2px 2px" }}>
                      <div>{product.description}</div>
                      <div>Quantity: {product.quantity}</div>
                      <div>&#x20B9; {product.amount_subtotal / 100}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div
            style={{ fontSize: "25px", textAlign: "center", marginTop: "30px" }}
          >
            Total : &#x20B9; {Amount}
          </div>
        </Box>
      </Modal>
      <Card sx={{ maxwidth: 345 }} style={{ margin: "40px auto" }}>
        <CardMedia
          component="img"
          height="140"
          image={design}
          alt="green iguana"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ fontFamily: "Josefin Sans" }}
          ></Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            Address: {Address}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ fontFamily: "Josefin Sans" }}
          >
            {restaurant.name}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            color="text.secondary"
            style={{ marginTop: "20px", fontFamily: "Josefin Sans" }}
          >
            {new Date(placedAt).toLocaleString()}
          </Typography>
        </CardContent>
        <CardActions>
          <div style={{ width: "100%", textAlign: "left" }}>
            <img
              src={delievery}
              alt="delievery"
              style={{ width: "40px", height: "40px" }}
            />
            <span style={{ padding: "5px 10px" }}>{status}</span>
          </div>
          <div style={{ width: "100%", textAlign: "right" }}>
            <button className="view-menu-btn" onClick={handleOpen}>
              View Order
            </button>
            <button className="view-menu-btn" onClick={changeStatus}>
              Deliever
            </button>
            <button className="view-menu-btn" onClick={cancelOrder}>
              Cancel
            </button>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default OrderCard;
